import _esArray from "../../modules/es.array.iterator";
import _esObject from "../../modules/es.object.to-string";
import _es from "../../modules/es.set";
import _esString from "../../modules/es.string.iterator";
import _path from "../../internals/path";
var exports = {};
_esArray;
_esObject;
_es;
_esString;
var path = _path;
exports = path.Set;
export default exports;